import { Button } from '@/components/button';
import { Modal } from '@/components/modal';

export function ForceUserRefreshDialog() {
  const reload = () => location.reload();
  return (
    <Modal title="Please sign in again" isOpen={true} onClose={reload} includeCloseButton={false}>
      <div className="mt-4 text-sm text-gray-700">
        You were signed-in or signed-out of your account in a different tab. Please press "Reload"
        to continue.
      </div>
      <div className="mt-5 sm:mt-6">
        <Button variant="blue" className="w-full font-bold tracking-wide" onClick={reload}>
          Reload
        </Button>
      </div>
    </Modal>
  );
}
