import logoUrl from '@/assets/logo-light.svg';
import { userConfig } from '@/auth/user-schemas';
import { useUser } from '@/auth/user-store';
import { classNames } from '@/utils/class-names';
import { allPages } from '@/utils/pages';
import { truthy } from '@/utils/typescript';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline/index.js';
import { Fragment } from 'react';
import { usePageContext } from 'vike-react/usePageContext';
import { Link } from './link';

type Navigation = {
  name: string;
  href?: string;
  onClick?: () => void;
  current?: boolean;
}[];

export default function Nav() {
  const {
    isSignedIn,
    user: { firstName, lastName, email, type: userType, dashboardPath } = {},
    signOut,
  } = useUser();

  const { urlPathname } = usePageContext();

  const navigation: Navigation = [
    dashboardPath && {
      name: userType ? allPages[userConfig[userType].dashboardPath] : 'Dashboard',
      href: dashboardPath,
      current: urlPathname.startsWith(dashboardPath),
    },
    !isSignedIn && {
      name: 'Sign In',
      href: '/sign-in/',
      current: urlPathname.startsWith('/sign-in/'),
    },
  ].filter(truthy);

  const userNavigation: Navigation = [
    dashboardPath && { name: 'Dashboard', href: dashboardPath },
    { name: 'Sign out', onClick: signOut },
  ].filter(truthy);

  const charsInCircle = (
    firstName && lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : (email || '?').slice(0, 2)
  ).toUpperCase();

  const fullName = firstName && lastName ? `${firstName} ${lastName}` : '';

  return (
    <>
      <Disclosure as="nav" className="bg-green-900">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Link to="/">
                      <img className="h-7" src={logoUrl} alt="Redwood Materials" width={110} />
                    </Link>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-green-700 text-white'
                              : 'text-gray-200 transition-colors hover:bg-green-700 hover:text-white',
                            'rounded-md px-3 py-2 text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                {isSignedIn && (
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex h-8 w-8 items-center justify-center rounded-full bg-green-700 shadow-inner transition-colors hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <div className="text-sm font-bold tracking-wider text-white">
                              {charsInCircle}
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="block w-full px-4 py-2 text-left text-xs text-gray-700">
                              {fullName && <div>{fullName}</div>}
                              {email && <div className="truncate">{email}</div>}
                            </div>
                            <hr />
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => {
                                  const className = classNames(
                                    active ? 'bg-gray-100' : 'rw-gr',
                                    'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                  );
                                  return item.onClick ? (
                                    <button onClick={item.onClick} className={className}>
                                      {item.name}
                                    </button>
                                  ) : (
                                    // TODO -> convert <a> to <Link>
                                    <a href={item.href} className={className}>
                                      {item.name}
                                    </a>
                                  );
                                }}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                )}
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-green-700 p-2 text-gray-100 transition-colors hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <MobileMenu navigation={navigation} userNavigation={userNavigation} />
          </>
        )}
      </Disclosure>

      {import.meta.env.MODE !== 'production' && (
        <p className="fixed bottom-0 left-0 z-50 rounded-md bg-yellow-200 px-2 py-1">
          {import.meta.env.MODE}
        </p>
      )}
    </>
  );
}

function MobileMenu({
  navigation,
  userNavigation,
}: {
  navigation: Navigation;
  userNavigation: Navigation;
}) {
  const { isSignedIn, user: { firstName, lastName, email } = {} } = useUser();

  const charsInCircle = (
    firstName && lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : (email || '?').slice(0, 2)
  ).toUpperCase();

  const fullName = firstName && lastName ? `${firstName} ${lastName}` : '';

  return (
    <Disclosure.Panel className="md:hidden">
      <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
        {navigation.map((item) => (
          <Disclosure.Button
            key={item.name}
            as="a"
            href={item.href}
            className={classNames(
              item.current
                ? 'bg-green-700 p-2 text-white transition-colors hover:bg-green-600'
                : 'text-gray-100 hover:bg-green-700 hover:text-white',
              'block rounded-md px-3 py-2 text-base font-medium'
            )}
            aria-current={item.current ? 'page' : undefined}
          >
            {item.name}
          </Disclosure.Button>
        ))}
      </div>
      {isSignedIn && (
        <div className="bg-green-800 pb-3 pt-4">
          <div className="flex items-center px-5">
            <div className="flex-shrink-0">
              <span className="flex h-9 w-9 items-center justify-center rounded-full bg-green-700 shadow-inner focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                <span className="sr-only">Open user menu</span>
                <div className="text-md font-bold tracking-wider text-white">{charsInCircle}</div>
              </span>
            </div>
            <div className="ml-3">
              <div className="text-sm leading-4 text-white">
                {fullName && <div>{fullName}</div>}
                {email && <div className="truncate">{email}</div>}
              </div>
            </div>
          </div>
          <div className="mt-3 space-y-1 px-2">
            {userNavigation.map((item) => {
              const className =
                'block rounded-md px-3 py-2 text-base font-medium text-gray-100 hover:bg-green-700 hover:text-white w-full text-left';
              return item.onClick ? (
                <Disclosure.Button
                  key={item.name}
                  as="button"
                  onClick={item.onClick}
                  className={className}
                >
                  {item.name}
                </Disclosure.Button>
              ) : (
                <Disclosure.Button key={item.name} as="a" href={item.href} className={className}>
                  {item.name}
                </Disclosure.Button>
              );
            })}
          </div>
        </div>
      )}
    </Disclosure.Panel>
  );
}
