import { components as zodSchemasFromApi } from '@/api/cp-openapi-zod-export';
import { UserRoles, userRolesSchema } from '@/api/endpoints/supplier-users';
import {
  gmSupplierId,
  panasonicSupplierId,
  ultiumSupplierId,
} from '@/components/suppliers/supplier-ids-in-erp';
import { PagePath } from '@/utils/pages';
import { vwDealerCodeRegex } from '@/utils/regex';
import { reportSentryError, reportSentryWarning } from '@/utils/sentry';
import { KeysOfUnion, includes, objectKeys, truthy } from '@/utils/typescript';
import { isEqual } from 'lodash-es';
import { ZodIssue, z } from 'zod';
import { UserPool } from './cognito-user-pools';

// Ensure user groups from API match the ones that the UI is using
export const expectedUserGroups = [
  // For user management (adding/removing groups from other users)
  'cognito-admin-cp',
  // For customer portal API & supplier portal API actions
  'cp-admin',
  'cp-rw-bizdev-customerops',
  'cp-rw-bizdev-managers',
  'cp-rw-logistics',
] as const;
const expectedUserGroupsSchema = z.array(z.enum(expectedUserGroups));
const vwgoaUserPermissionsSchema = zodSchemasFromApi.schemas.VwgoaUserPermissions;

//
// Types of users on the customer portal (used for access control)
//

// Properites shared by all users in all Cognito user pools used by this app
// Intentionally kept loose to only require attributes that must always exist
// We want to avoid unnecessarily strict schemas b/c if schema validation fails
// then pre-processing (e.g. for dealer codes) will not take place and downstream
// errors in React components are likely.
const baseUserSchema = z.object({
  id: z.string().uuid(),
  username: z.string(), // Not a pretty value, generated automatically by Cognito
  email: z.string().email(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phone: z.string().optional(),
  // Note: a user may have other groups but we explicitly whittle them down to only
  // the ones we care about to avoid typos
  groups: expectedUserGroupsSchema,
});

// A union of all possible user types and their associated properties (derived from Cognito user attributes)
// The "type" property is an abstraction we use just to tell different user types apart
export const userSchema = z.discriminatedUnion('type', [
  // Unknown/invalid user (unable to determine user type based on Cognito attributes)
  baseUserSchema.extend({ type: z.literal('unknown'), tenant: z.string().optional() }),
  // Redwood users (via Okta)
  baseUserSchema.extend({
    type: z.literal('redwood-admin'),
    tenant: z.literal('redwood'),
    groups: expectedUserGroupsSchema.refine((g) => g.includes('cp-admin')),
  }),
  baseUserSchema.extend({
    type: z.literal('redwood-bizdev-customerops'),
    tenant: z.literal('redwood'),
    groups: expectedUserGroupsSchema.refine((g) => g.includes('cp-rw-bizdev-customerops')),
  }),
  baseUserSchema.extend({
    type: z.literal('redwood-bizdev-managers'),
    tenant: z.literal('redwood'),
    groups: expectedUserGroupsSchema.refine((g) => g.includes('cp-rw-bizdev-managers')),
  }),
  baseUserSchema.extend({
    type: z.literal('redwood-logistics'),
    tenant: z.literal('redwood'),
    groups: expectedUserGroupsSchema.refine((g) => g.includes('cp-rw-logistics')),
  }),
  baseUserSchema.extend({
    type: z.literal('redwood-lowest-tier'),
    tenant: z.literal('redwood'),
  }),
  // VWGoA dealer
  baseUserSchema.extend({
    type: z.literal('vwgoa-dealer'),
    tenant: z.literal('vwgoa'),
    dealerCodes: z.array(z.string().regex(vwDealerCodeRegex)),
    limitDealershipAccessBy: z.tuple([z.literal('dealerCodes')]),
    activeDealerCode: z.string().regex(vwDealerCodeRegex).optional(),
  }),
  // VWGoA corporate user (readonly access to all or a subset of dealerships)
  baseUserSchema.extend({
    type: z.literal('vwgoa-corporate'),
    tenant: z.literal('vwgoa'),
    limitDealershipAccessBy: z.array(z.enum(['region', 'brand', 'dealerCodes'])),
    activeDealerCode: z.string().regex(vwDealerCodeRegex).optional(),
  }),
  // VWGoA admin user  (readonly access to all dealerships, can export program data)
  baseUserSchema.extend({
    type: z.literal('vwgoa-admin'),
    tenant: z.literal('vwgoa'),
    limitDealershipAccessBy: z.tuple([]),
    activeDealerCode: z.string().regex(vwDealerCodeRegex).optional(),
  }),
  // Auto Dismantler lead (self-signup, approved by biz-dev)
  baseUserSchema.extend({
    type: z.literal('auto-dismantler-lead'),
    tenant: z.literal('lead').optional(), // Pre-approval, leads don't have a tenant
    // The "untrusted" prefix is just a reminder that these fields are not
    // verified in any way, just input by user during sign-up
    untrustedCompanyName: z.string().optional(),
    untrustedCompanyLocation: z.string().optional(),
    acceptedTermsOfUse: z.string().optional(), // Not used for anything, just a record of sign-up
  }),
  // Contact associated with a supplier in ERP
  // May have initially been an auto dismantler lead
  baseUserSchema.extend({
    type: z.literal('supplier'),
    tenant: z.literal('supplier'),
    supplierId: z.string(),
    supplierName: z.string(),
  }),
  // Ultium user (just a supplier user associated with Ultium)
  baseUserSchema.extend({
    type: z.literal('ultium'),
    tenant: z.literal('supplier'),
    supplierId: z.literal(ultiumSupplierId),
    supplierName: z.string(),
  }),
  // Panasonic user (just a supplier user associated with Panasonic)
  baseUserSchema.extend({
    type: z.literal('panasonic'),
    tenant: z.literal('supplier'),
    supplierId: z.literal(panasonicSupplierId),
    supplierName: z.string(),
  }),
  // GM Facilities user (just a supplier user associated with GM)
  baseUserSchema.extend({
    type: z.literal('gm-facilities'),
    tenant: z.literal('supplier'),
    supplierId: z.literal(gmSupplierId),
    supplierName: z.string(),
  }),
  // BMW user
  baseUserSchema.extend({
    type: z.literal('bmw-dealer'),
    tenant: z.literal('bmw_group'),
    bmwLocationId: z.string().optional(), // Only set if user has access to a single location
  }),
  // Porsche user
  baseUserSchema.extend({
    type: z.literal('porsche-dealer'),
    tenant: z.literal('porsche'),
    porscheLocationId: z.string().optional(), // Only set if user has access to a single location
  }),
]);

export type UserSchema = z.infer<typeof userSchema>;
export type UserType = UserSchema['type'];
export type UserTenant = UserSchema['tenant'];
export type UserGroup = (typeof expectedUserGroups)[number];

export const cognitoAttributeMap: Record<string, Exclude<KeysOfUnion<UserSchema>, 'type'>> = {
  // Map of Cognito attribute names friendly user property names
  sub: 'id',
  'cognito:username': 'username',
  email: 'email',
  given_name: 'firstName',
  family_name: 'lastName',
  phone_number: 'phone',
  'custom:tenant': 'tenant',
  // For self-sign-up auto dismantler leads
  'custom:company_name': 'untrustedCompanyName',
  'custom:company_location': 'untrustedCompanyLocation',
  'custom:accept_terms_of_use': 'acceptedTermsOfUse',
  // For suppliers in ERP
  'custom:oracle_supplier_id': 'supplierId',
  'custom:oracle_supplier_name': 'supplierName',
};
export type CognitoIdTokenPayload = { [key in keyof typeof cognitoAttributeMap]: string } & {
  'cognito:groups'?: string[];
  vwgoa_user_permissions?: string;
  vwgoa_active_dealer_code?: string;
  user_roles?: string;
  iat: number;
};

export const redwoodUserTypes = [
  'redwood-admin',
  'redwood-bizdev-customerops',
  'redwood-bizdev-managers',
  'redwood-logistics',
  'redwood-lowest-tier',
] as const;
export type RedwoodUserType = (typeof redwoodUserTypes)[number];

// Miscellaneous user-type-specific metadata that's not stored in Cognito
// * dashboardPath -> where to send user after they login
export const userConfig: Record<UserType, { dashboardPath: PagePath }> = {
  // Redwood Employee (login via Okta)
  'redwood-admin': { dashboardPath: '/dashboard/' },
  'redwood-bizdev-customerops': { dashboardPath: '/dashboard/' },
  'redwood-bizdev-managers': { dashboardPath: '/dashboard/' },
  'redwood-logistics': { dashboardPath: '/dashboard/shipments/' },
  'redwood-lowest-tier': { dashboardPath: '/dashboard/' },
  // VWGoA Dealership Employee
  'vwgoa-dealer': { dashboardPath: '/vwgoa/recycling-requests/' },
  // VWGoA Corporate Employee
  'vwgoa-corporate': { dashboardPath: '/vwgoa/recycling-requests/' },
  // VWGoA Admin (one of our direct contacts at the company)
  'vwgoa-admin': { dashboardPath: '/vwgoa/' },
  // Auto dismantler lead (signed up on /sell-my-battery/ page)
  'auto-dismantler-lead': { dashboardPath: '/sell-my-battery/' },
  // User associated with any supplier in Oracle ERP
  supplier: { dashboardPath: '/suppliers/' },
  // User associated with Ultium supplier in Oracle ERP
  ultium: { dashboardPath: '/ultium/' },
  // User associated with Panasonic supplier in Oracle ERP
  panasonic: { dashboardPath: '/panasonic/' },
  // User associated with GM Facilities supplier in Oracle ERP
  'gm-facilities': { dashboardPath: '/gm-facilities/' },
  // BMW Dealership Employee
  'bmw-dealer': { dashboardPath: '/bmw/' },
  // Porsche Dealership Employee
  'porsche-dealer': { dashboardPath: '/porsche/' },
  // Unknown user type
  unknown: { dashboardPath: '/' },
};

export const userTypeToUserPoolMap: Record<Exclude<UserType, 'unknown'>, UserPool> = {
  // Redwood Employee (login via Okta)
  'redwood-admin': 'redwood_okta',
  'redwood-bizdev-customerops': 'redwood_okta',
  'redwood-bizdev-managers': 'redwood_okta',
  'redwood-logistics': 'redwood_okta',
  'redwood-lowest-tier': 'redwood_okta',
  // VWGoA employees log in via their SSO providers
  'vwgoa-dealer': 'vwgoa_sso',
  'vwgoa-corporate': 'vwgoa_sso',
  'vwgoa-admin': 'vwgoa_sso',
  // Auto dismantler lead (signed up on /sell-my-battery/ page)
  'auto-dismantler-lead': 'external',
  // User associated with suppliers in Oracle ERP
  supplier: 'external',
  ultium: 'external',
  panasonic: 'external',
  'gm-facilities': 'external',
  // BMW employees have a dedicated user pool
  'bmw-dealer': 'bmw_group',
  // Porsche employees have a dedicated user pool
  'porsche-dealer': 'porsche',
};

//
// Utils
//

export function getUserFromIdToken(idToken: CognitoIdTokenPayload): {
  user: UserSchema;
  validationIssues?: ZodIssue[];
} {
  // Map cognito attribute names to friendly property names
  const friendlyAttributeData = Object.fromEntries(
    Object.entries(idToken)
      .map(([cognitoName, value]) =>
        cognitoName in cognitoAttributeMap ? [cognitoAttributeMap[cognitoName], value] : undefined
      )
      .filter(truthy)
  );

  // Create user data object (not yet validated by schema)
  const userData: UserSchema = {
    type: getUserType(idToken),
    groups: getUserGroups(idToken),
    ...friendlyAttributeData,
  };

  // Report if unable to get a user's type. Means we likely haven't set a tenant in Cognito
  if (userData.type === 'unknown') {
    reportSentryWarning('Failed to determine user type', idToken);
  }

  // For VW dealers, get dealer codes from vwgoa_user_permissions attribute
  if (userData.type === 'vwgoa-dealer') {
    userData.dealerCodes = getVwgoaDealerCodes(idToken);
  }

  // For all VW users, indicate what fields limit their dealership access (e.g. by brand, region, and/or dealer code)
  // We don't use this for access control (API handles that), but rather for what UI filters to show them
  if (
    userData.type === 'vwgoa-dealer' ||
    userData.type === 'vwgoa-corporate' ||
    userData.type === 'vwgoa-admin'
  ) {
    userData.limitDealershipAccessBy = getVwgoaDealershipAccessLimits(idToken)!;
    userData.activeDealerCode = getVwgoaActiveDealerCode(idToken!);
  }

  // If BMW dealer has access to a single location, add it to their user data (to avoid showing them dropdown filters)
  if (userData.type === 'bmw-dealer') {
    userData.bmwLocationId = getBmwUserSingleLocationId(idToken);
  }

  // If Porsche dealer has access to a single location, add it to their user data (to avoid showing them dropdown filters)
  if (userData.type === 'porsche-dealer') {
    userData.porscheLocationId = getPorscheUserSingleLocationId(idToken);
  }

  // User attributes should match Zod schema
  const validationResult = userSchema.safeParse(userData);
  if (validationResult.success) {
    return {
      user: validationResult.data,
    };
  }

  // User attributes did not match Zod schema. Report a warning in Sentry but still
  // display a logged-in state so that user can log out. There may be runtime errors
  // but at least we know why and can fix them in Cognito.
  reportSentryWarning(`Cognito ${userData.type} user failed validation`, {
    userData,
    issues: validationResult.error.issues,
    payload: idToken,
  });
  return {
    user: userData,
    validationIssues: validationResult.error.issues,
  };
}

export function getUserType(idToken: CognitoIdTokenPayload): UserType {
  const tenant: string = idToken['custom:tenant'] || '';
  const oracleSupplierId: string = idToken['custom:oracle_supplier_id'] || '';

  if (tenant === 'redwood') return getRedwoodUserType(getUserGroups(idToken));
  if (tenant === 'supplier') {
    if (oracleSupplierId === ultiumSupplierId) return 'ultium';
    if (oracleSupplierId === panasonicSupplierId) return 'panasonic';
    return 'supplier';
  }
  if (tenant === 'vwgoa') return getVwgoaSsoUserType(idToken);
  if (tenant === 'bmw_group') {
    const userRoles = getSupplierUserRoles(idToken);
    if (userRoles) return 'bmw-dealer';
  }
  if (tenant === 'porsche') {
    const userRoles = getSupplierUserRoles(idToken);
    if (userRoles) return 'porsche-dealer';
  }
  if (
    tenant === 'lead' ||
    idToken['custom:accept_terms_of_use'] ||
    idToken['custom:company_name']
  ) {
    return 'auto-dismantler-lead';
  }
  return 'unknown';
}

export function getRedwoodUserType(groups: UserGroup[]): RedwoodUserType {
  if (groups.includes('cp-admin')) return 'redwood-admin';
  if (groups.includes('cp-rw-bizdev-customerops')) return 'redwood-bizdev-customerops';
  if (groups.includes('cp-rw-bizdev-managers')) return 'redwood-bizdev-managers';
  if (groups.includes('cp-rw-logistics')) return 'redwood-logistics';
  return 'redwood-lowest-tier';
}

function getVwgoaSsoUserType(idToken: CognitoIdTokenPayload): UserType {
  const permissions = getVwgoaUserPermissions(idToken);
  if (permissions) {
    const { allowedActions, dealershipAccess } = permissions;
    if (dealershipAccess === 'ALL_DEALERSHIPS' && allowedActions.includes('admin')) {
      return 'vwgoa-admin';
    }
    if (allowedActions.includes('write')) {
      return 'vwgoa-dealer';
    }
    if (isEqual(allowedActions, ['read'])) {
      return 'vwgoa-corporate';
    }
  }
  return 'unknown';
}

function getVwgoaUserPermissions(idToken: CognitoIdTokenPayload) {
  try {
    return vwgoaUserPermissionsSchema.parse(JSON.parse(idToken['vwgoa_user_permissions'] || ''));
  } catch (error) {
    reportSentryWarning('vwgoa_user_permissions failed validation', { cause: error as Error });
  }
}

function getVwgoaDealerCodes(idToken: CognitoIdTokenPayload): string[] {
  if (idToken['vwgoa_user_permissions']) {
    const permissions = getVwgoaUserPermissions(idToken);
    return (
      (permissions &&
        typeof permissions.dealershipAccess === 'object' &&
        permissions.dealershipAccess.dealerCodes) ||
      []
    );
  }
  return [];
}

function getVwgoaDealershipAccessLimits(
  idToken: CognitoIdTokenPayload
): ('region' | 'brand' | 'dealerCodes')[] | undefined {
  if (idToken['vwgoa_user_permissions']) {
    const { dealershipAccess } = getVwgoaUserPermissions(idToken)!;
    return dealershipAccess === 'ALL_DEALERSHIPS'
      ? []
      : objectKeys(dealershipAccess).filter(truthy);
  }

  const groups: string[] = idToken['cognito:groups'] || [];
  reportSentryError('Failed to get dealership access', {
    groups,
    permissions: idToken['vwgoa_user_permissions'],
  });
}

// Used to select a dealer by default if the user has multiple
function getVwgoaActiveDealerCode(idToken: CognitoIdTokenPayload): string | undefined {
  const { vwgoa_active_dealer_code: activeDealerCode } = idToken;
  if (activeDealerCode && vwDealerCodeRegex.test(activeDealerCode)) return activeDealerCode;
}

export function getUserGroups(idToken: CognitoIdTokenPayload) {
  // Only return expected groups. Eventually we may do additional filtering by user type
  return (idToken['cognito:groups'] || []).filter((group) =>
    includes(expectedUserGroups, group)
  ) as UserGroup[];
}

export function getSupplierUserRoles(idToken: CognitoIdTokenPayload): UserRoles | undefined {
  try {
    return userRolesSchema.parse(JSON.parse(idToken.user_roles || ''));
  } catch (error) {
    reportSentryWarning('user_roles failed validation', { cause: error as Error });
  }
}

// If BMW user is limited to a single location, return it
function getBmwUserSingleLocationId(idToken: CognitoIdTokenPayload): string | undefined {
  const { allowedSites } = getSupplierUserRoles(idToken)!;
  if (allowedSites.length === 1 && allowedSites[0].startsWith('bmw_group:')) {
    return allowedSites[0].replace('bmw_group:', '');
  }
}

// If Porsche user is limited to a single location, return it
function getPorscheUserSingleLocationId(idToken: CognitoIdTokenPayload): string | undefined {
  const { allowedSites } = getSupplierUserRoles(idToken)!;
  if (allowedSites.length === 1 && allowedSites[0].startsWith('porsche:')) {
    return allowedSites[0].replace('porsche:', '');
  }
}

const tenantsForOneCompany = ['redwood', 'vwgoa'] as const;

export function getUserCompanyName(user: UserSchema): string | undefined {
  // User is a supplier contact in ERP (attribute set to ERP supplier name)
  if (user.type === 'supplier') return user.supplierName;

  // User is an auto-dismantler lead (they input a company name during sign-up)
  if (user.type === 'auto-dismantler-lead') return user.untrustedCompanyName;

  // User's tenant determines company affiliation
  if (includes(tenantsForOneCompany, user.tenant)) return getCompanyNames(user.tenant).long;
}

export function getCompanyNames(tenant: (typeof tenantsForOneCompany)[number]): {
  short: string;
  long: string;
} {
  const companyNames = {
    redwood: { short: 'Redwood', long: 'Redwood Materials' },
    vwgoa: { short: 'VWGoA', long: 'Volkswagen Group of America' },
  };
  return companyNames[tenant];
}
